import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  paperContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 20,
  },
  button: {
    width: '100%'
  },
  select: {
    width: '100%',
    marginBottom: 30,
  },
  label: {
    fontWeight: 'bold',
    marginTop: 15,
    lineHeight: 1.2,
    fontSize: '1.2rem',
  },
}));

function Form({ sitesList }: { sitesList: any }) {
  const classes = useStyles();

  const [siteId, setSiteId] = useState((sitesList[0] || {}).id);

  const handleChangeSiteId = (event: any) => {
    setSiteId(event.target.value);
  };

  const handleSubmit = () => {
    const site = sitesList.find((site: any) => site.id === siteId);
    window.open(site.templateLog);
  }

  return (
    <Paper elevation={3} className={classes.paperContainer}>
      <Box m={1}>
        <InputLabel htmlFor="site" className={classes.label}>Choose the Job site</InputLabel>
        <Select labelId="site" id="select" value={siteId} onChange={handleChangeSiteId} className={classes.select}>
          {sitesList.map((item: any) => {
            return (<MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)
          })}
        </Select>

        <Button variant="contained" size="large" color="primary" onClick={handleSubmit} className={classes.button}>
          Open Template
        </Button>
      </Box>
    </Paper>
  )
}

export default Form;