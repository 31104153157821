import React, { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { makeStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

type TranslationType = Record<string, string>
type translationsType = Record<string, TranslationType>;
type Language = 'english' | 'spanish';

const translations: translationsType = {
  english: {
    firstName: 'What is your first name?',
    lastName: 'What is your last name?',
    past14days: 'In the past 14 days:',
    contact: 'Have you been in proximate contact  with anyone who has tested positive for COVID-19 or who has or had symptoms of COVID-19?',
    tested: 'Have you tested positive for COVID-19 in the past 14 days?',
    lastDay: 'Since your last day of work, are you experiencing any of the below conditions?',
    fever: 'Fever 100.4F or higher?',
    flu: 'Flu like symptoms?',
    cough: 'Cough or respiratory illness not related to another health condition?',
    breath: 'Shortness of breath?',
    sore: 'Sore throat not related to any other health condition?',
    nausea: 'Nausea, diarrhea or vomiting?',
    yes: 'Yes',
    no: 'No',
    submit: 'Submit',
    confirm: 'I understand',
    thanks: 'Thank you for checking in',
    warning: 'DO NOT REPORT TO WORK AND CONTACT THE OFFICE (212-643-0101) AND YOUR SUPERVISOR!',
    warning_highline: 'DO NOT REPORT TO WORK AND CONTACT THE OFFICE (212-564-0600) AND YOUR SUPERVISOR!',
    welcome: 'Thank you for checking in. Please report to work as usual.',
    disabled: 'Please fill out all the fields',
    site: 'Choose your job site:'
  },
  spanish: {
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    past14days: 'En los últimos 14 días',
    contact: '¿Has estado en contacto inmediato con alguien que haya dado positivo para COVID-19 o que tenga o haya tenido síntomas de COVID-19?',
    tested: '¿Ha dado positivo por COVID-19 en los últimos 14 días?',
    lastDay: 'Desde su último día de trabajo, ¿está experimentando alguna de las siguientes condiciones?',
    fever: '¿Tiene una temperatura corporal de 100.4F o más?',
    flu: '¿Se siente mal?',
    cough: '¿Tienes tos?',
    breath: '¿Dificultad para respirar?',
    sore: '¿Dolor de garganta?',
    nausea: '¿Dolor de cuerpo?',
    yes: 'Sí',
    no: 'No',
    submit: 'Enviar',
    confirm: 'Confirmo',
    thanks: 'Thank you for checking in',
    warning: '¡NO REPORTE AL TRABAJO Y CONTACTE CON LA OFICINA (212-643-0101) Y SU SUPERVISOR!',
    welcome: 'Gracias por registrarse. Por favor, informe a trabajar como de costumbre.',
    disabled: 'Por favor complete todos los campos',
    site: 'Elige tu sitio de trabajo:',
  }
};

Form.defaultProps = {
  sitesList: []
}

const useStyles = makeStyles(() => ({
  paperContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 20,
  },
  button: {
    width: '100%'
  },
  fields: {
    marginTop: 15,
    width: '100%'
  },
  select: {
    width: '100%'
  },
  label: {
    fontWeight: 'bold',
    marginTop: 15,
    lineHeight: 1.2,
    fontSize: '1.2rem',
  },
  languages: {
    textAlign: 'right',
    left: 'auto',
    right: 0,
    display: 'block',
    marginBottom: 10,
  },
  warning: {
    fontWeight: 'bold',
    color: 'red',
  },
  welcome: {
    fontWeight: 'bold',
  }
}));

function Form({ isHighline, sitesList }: { isHighline: boolean, sitesList: any }) {
  const classes = useStyles();

  const [language, setLanguage] = useState('english');
  const [cookies, setCookie] = useCookies(['pbs-form']);

  const [values, setValues] = useState({
    firstName: cookies.firstName || null,
    lastName: cookies.lastName || null,
    contact: null,
    tested: null,
    lastDay: null,
    fever: null,
    flu: null,
    cough: null,
    breath: null,
    sore: null,
    nausea: null,
  });

  const [siteId, setSiteId] = useState((sitesList[0] || {}).id);
  const [openDialog, setOpenDialog] = useState(false);

  const handleSubmit = () => {
    setOpenDialog(true);

    const postData = async () => {
      const result = await axios.post(
        '/api/submitForm',
        {
          ...values,
          domain: isHighline ? 'highline' : 'principal',
          siteId
        },
      );
    };

    postData();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const currentTranslation = translations[language];

  const handleSetLanguage = (event: React.MouseEvent<HTMLElement, MouseEvent>, newLanguage: Language) => {
    setLanguage(newLanguage);
  };

  const handleChangeSiteId = (event: any) => {
    setSiteId(event.target.value);
  };

  const handleChange = (key: any, event: any) => {
    setValues({
      ...values,
      [key]: event.target.value
    });
  };

  const showWarning = (
    values.contact === 'yes' ||
    values.nausea === 'yes' ||
    values.sore === 'yes' ||
    values.breath === 'yes' ||
    values.cough === 'yes' ||
    values.flu === 'yes' ||
    values.fever === 'yes' ||
    values.tested === 'yes'
  );

  const enableSubmit = (
    values.firstName !== null &&
    values.lastName !== null &&
    values.firstName !== '' &&
    values.lastName !== '' &&
    values.contact !== null &&
    values.nausea !== null &&
    values.sore !== null &&
    values.breath !== null &&
    values.cough !== null &&
    values.flu !== null &&
    values.fever !== null &&
    values.tested !== null
  );

  return (
    <form noValidate autoComplete="off">
      <Paper elevation={3} className={classes.paperContainer}>
        <Box m={1}>
          <ToggleButtonGroup
            value={language}
            exclusive
            className={classes.languages}
            onChange={handleSetLanguage}
            aria-label="Set Language"
          >
            <ToggleButton value="english" aria-label="left aligned">
              English
          </ToggleButton>
            <ToggleButton value="spanish" aria-label="justified">
              Spanish
          </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box m={1}>
          <InputLabel htmlFor="site" className={classes.label}>{currentTranslation.site}</InputLabel>
          <Select labelId="site" id="select" value={siteId} onChange={handleChangeSiteId} className={classes.select}>
            {sitesList.map((item: any) => {
              return (<MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)
            })}
          </Select>
        </Box>
        <Box m={1}>
          <TextField id="outlined-basic" label={currentTranslation.firstName} className={classes.fields} value={values.firstName} variant="outlined" onChange={(event) => {
            handleChange('firstName', event)
            setCookie('firstName', event.target.value, { path: '/' });
          }} />
        </Box>
        <Box m={1}>
          <TextField id="outlined-basic" label={currentTranslation.lastName} className={classes.fields} value={values.lastName} variant="outlined" onChange={(event) => {
            handleChange('lastName', event)
            setCookie('lastName', event.target.value, { path: '/' });
          }} />
        </Box>
      </Paper>

      <Paper elevation={3} className={classes.paperContainer}>
        <Box m={1}>
          <Typography variant="h5" component="h2" gutterBottom>
            {currentTranslation.past14days}
          </Typography>
          <Divider />

          <FormLabel component="legend" className={classes.label}>{currentTranslation.contact}</FormLabel>
          <RadioGroup aria-label="contact" name="contact" value={values.contact}
            onChange={(event) => {
              handleChange('contact', event)
            }}>
            <FormControlLabel value="yes" control={<Radio />} label={currentTranslation.yes} />
            <FormControlLabel value="no" control={<Radio />} label={currentTranslation.no} />
          </RadioGroup>

          <FormLabel component="legend" className={classes.label}>{currentTranslation.tested}</FormLabel>
          <RadioGroup aria-label="tested" name="tested" value={values.tested}
            onChange={(event) => {
              handleChange('tested', event)
            }}>
            <FormControlLabel value="yes" control={<Radio />} label={currentTranslation.yes} />
            <FormControlLabel value="no" control={<Radio />} label={currentTranslation.no} />
          </RadioGroup>
        </Box>
      </Paper>

      <Paper elevation={3} className={classes.paperContainer}>
        <Box m={1}>
          <Typography variant="h5" component="h1" gutterBottom>
            {currentTranslation.lastDay}
          </Typography>

          <Divider />

          <FormLabel component="legend" className={classes.label}>{currentTranslation.fever}</FormLabel>
          <RadioGroup aria-label="fever" name="fever" value={values.fever}
            onChange={(event) => {
              handleChange('fever', event)
            }}>
            <FormControlLabel value="yes" control={<Radio />} label={currentTranslation.yes} />
            <FormControlLabel value="no" control={<Radio />} label={currentTranslation.no} />
          </RadioGroup>

          <FormLabel component="legend" className={classes.label}>{currentTranslation.flu}</FormLabel>
          <RadioGroup aria-label="flu" name="flu" value={values.flu}
            onChange={(event) => {
              handleChange('flu', event)
            }}>
            <FormControlLabel value="yes" control={<Radio />} label={currentTranslation.yes} />
            <FormControlLabel value="no" control={<Radio />} label={currentTranslation.no} />
          </RadioGroup>

          <FormLabel component="legend" className={classes.label}>{currentTranslation.cough}</FormLabel>
          <RadioGroup aria-label="cough" name="cough" value={values.cough}
            onChange={(event) => {
              handleChange('cough', event)
            }}>
            <FormControlLabel value="yes" control={<Radio />} label={currentTranslation.yes} />
            <FormControlLabel value="no" control={<Radio />} label={currentTranslation.no} />
          </RadioGroup>

          <FormLabel component="legend" className={classes.label}>{currentTranslation.breath}</FormLabel>
          <RadioGroup aria-label="breath" name="breath" value={values.breath}
            onChange={(event) => {
              handleChange('breath', event)
            }}>
            <FormControlLabel value="yes" control={<Radio />} label={currentTranslation.yes} />
            <FormControlLabel value="no" control={<Radio />} label={currentTranslation.no} />
          </RadioGroup>

          <FormLabel component="legend" className={classes.label}>{currentTranslation.sore}</FormLabel>
          <RadioGroup aria-label="sore" name="sore" value={values.sore}
            onChange={(event) => {
              handleChange('sore', event)
            }}>
            <FormControlLabel value="yes" control={<Radio />} label={currentTranslation.yes} />
            <FormControlLabel value="no" control={<Radio />} label={currentTranslation.no} />
          </RadioGroup>

          <FormLabel component="legend" className={classes.label}>{currentTranslation.nausea}</FormLabel>
          <RadioGroup aria-label="nausea" name="nausea" value={values.nausea}
            onChange={(event) => {
              handleChange('nausea', event)
            }}>
            <FormControlLabel value="yes" control={<Radio />} label={currentTranslation.yes} />
            <FormControlLabel value="no" control={<Radio />} label={currentTranslation.no} />
          </RadioGroup>
        </Box>
      </Paper>

      {!enableSubmit && (
        <Button variant="contained" size="large" disabled className={classes.button}>
          {currentTranslation.disabled}
        </Button>
      )}

      {enableSubmit && (
        <Button variant="contained" size="large" color="primary" onClick={handleSubmit} className={classes.button}>
          {currentTranslation.submit}
        </Button>
      )}

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{currentTranslation.thanks}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={showWarning ? classes.warning : classes.welcome}>
            {showWarning
              ? (isHighline ? currentTranslation.warning_highline : currentTranslation.warning)
              : currentTranslation.welcome
            }
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            {currentTranslation.confirm}
          </Button>
        </DialogActions> */}
      </Dialog>
    </form>
  )
}

export default Form;