import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CookiesProvider } from 'react-cookie';

import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import Container from '@material-ui/core/Container';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';

import RestoreIcon from '@material-ui/icons/Restore';
import BookIcon from '@material-ui/icons/Book';
import 'fontsource-roboto';

import Form from './components/Form';
import Logs from './components/Logs';
import logo from './principal_logo.png';
import logoHighline from './highline_logo.png';

const themePBS = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

const themeHighline = createMuiTheme({
  palette: {
    primary: {
      light: '#b0b1bf',
      main: '#55565a',
      dark: '#2c2f35',
      contrastText: '#fff',
    },
    secondary: {
      light: '#5a2b22',
      main: '#4a3433',
      dark: '#c55e06',
      contrastText: '#000',
    },
  },
});

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 70,
    paddingBottom: 100,
  },

  appBar: {
    top: 'auto',
    bottom: 0,
  },
  title: {
    fontWeight: 'bold',
    flexGrow: 1,
  },
  logo: {
    flexGrow: 1,
    marginRight: 30,
    maxWidth: 90,
    width: 90,
  }
}));

function App({ isHighline }) {
  const classes = useStyles();

  const [value, setValue] = useState('checkin');
  const [sitesList, setSitesList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `/api/sites?domain=${isHighline ? 'highline' : 'principal'}`,
      );

      setSitesList(result.data);
    };

    fetchData();
  }, []);

  return (
    <CookiesProvider>
      <ThemeProvider theme={isHighline ? themeHighline : themePBS}>
        <CssBaseline />

        <AppBar color="primary">
          <Toolbar>
            {!isHighline && (
              <>
                <img src={logo} alt='Principal Building Services' className={classes.logo} />
                <Typography variant="h7" className={classes.title}>
                  Principal Building Services
                  Employee Daily Check-in
                </Typography>
              </>
            )}
            {isHighline && (
              <>
                <img src={logoHighline} alt='Highline Building Services' className={classes.logo} />
                <Typography variant="h7" className={classes.title}>
                  Highline Building Services
                  Employee Daily Check-in
                </Typography>
              </>
            )}
          </Toolbar>
        </AppBar>

        <div className={classes.container}>
          <Container maxWidth="sm">
            {sitesList && value === 'checkin' && (
              <Form sitesList={sitesList} isHighline={isHighline} />
            )}
            {sitesList && value === 'logs' && (
              <Logs sitesList={sitesList} />
            )}
          </Container>
        </div>

        <AppBar position="fixed" className={classes.appBar}>
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            showLabels
          >
            <BottomNavigationAction label="Check in" value="checkin" icon={<RestoreIcon />} />
            <BottomNavigationAction label="Logs" value="logs" icon={<BookIcon />} />
          </BottomNavigation>
        </AppBar>
      </ThemeProvider>
    </CookiesProvider>
  );
}

export default App;
